<template>
  <TTView>
    <VRow>
      <VCol>
        <ModuleNav :items="items" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { ModuleNav } from '@front.backoffice/shared';

export default {
  name: 'Dashboard',

  components: {
    ModuleNav,
  },

  inject: ['Names'],

  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Поиск матрицы',
          children: [
            {
              id: 1,
              icon: 'fal fa-search',
              title: 'Матрица компании',
              to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS_COMPANY_MATRIX },
            },
            {
              id: 2,
              icon: 'fal fa-search',
              title: 'Матрица пользователя',
              to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX },
            },
          ],
        },
        {
          id: 2,
          title: 'Позиции',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_COMPETENCY_MATRIX_POSITIONS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_COMPETENCY_MATRIX_POSITION_CREATE },
            },
          ],
        },
        {
          id: 3,
          title: 'Компетенции',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_COMPETENCY_MATRIX_COMPETENCIES },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_COMPETENCY_MATRIX_COMPETENCY_CREATE },
            },
          ],
        },
        {
          id: 4,
          title: 'Навыки',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_COMPETENCY_MATRIX_SKILLS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_COMPETENCY_MATRIX_SKILL_CREATE },
            },
          ],
        },
        {
          id: 5,
          title: 'Инструменты',
          children: [
            {
              id: 1,
              icon: 'fal fa-list',
              title: 'Список',
              to: { name: this.Names.R_COMPETENCY_MATRIX_INSTRUMENTS },
            },
            {
              id: 2,
              icon: 'fal fa-plus',
              title: 'Создать',
              to: { name: this.Names.R_COMPETENCY_MATRIX_INSTRUMENT_CREATE },
            },
          ],
        },
      ],
    };
  },
};
</script>
